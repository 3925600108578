import React, { useEffect, useState } from 'react';

import Form from 'components/commons/form';
import FilterMenu from 'components/views/columns/filter-menu';
import capitalize from 'lodash/capitalize';

import {
  Button,
  Chip,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
  FilterList as FilterIcon,
  Refresh as RefreshIcon,
  RemoveCircleOutline as RemoveCircleIcon
} from '@material-ui/icons';

import useStyles from './Column.styles';
import Body from './body';

const SEARCH_FILTER = "searched text";

const RemoveColumnButton = ({ onClick }) => (
  <Tooltip title="Remove Column" aria-label="remove-column">
    <IconButton onClick={onClick}>
      <RemoveCircleIcon />
    </IconButton>
  </Tooltip>
);

const RefreshColumnButton = ({ onClick }) => (
  <Tooltip title="Refresh Column" aria-label="refresh-column">
    <IconButton onClick={onClick}>
      <RefreshIcon />
    </IconButton>
  </Tooltip>
);

const FilterColumnButton = ({ id, onClick }) => (
  <Tooltip title="Filter Column" aria-label="filter-column">
    <IconButton id={id} onClick={onClick}>
      <FilterIcon />
    </IconButton>
  </Tooltip>
);

const Column = ({ name, actions, column, service, menuOptions, cardBuilder }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [form, setForm] = useState({
    highlightedText: '',
    searchText: ''
  });

  const classes = useStyles();
  useEffect(() => {
    actions.fetchColumnData({ name, columnId: column.id, filters: column.filters, fetchData: service });
    const initSearch = column.filters[SEARCH_FILTER]?.length ? column.filters[SEARCH_FILTER][0] : null;
    if (initSearch) {
      setForm({
        searchText: initSearch,
        highlightedText: initSearch
      })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptySearchFilter = () => {
    const { filters } = column;
    const newFilters = { ...filters, [SEARCH_FILTER]: [] };
    actions.updateFilters({ name, columnId: column.id, filters: newFilters });
  }

  const handleAddFilter = newFilter => {
    const { filters } = column;
    // Don't add an existing filter
    if (filters[newFilter.type]?.includes(newFilter.value)) {
      return;
    }
    const oldFilters = filters[newFilter.type] ? filters[newFilter.type] : []
    let newFilters = {
      ...filters,
      [newFilter.type]: [...oldFilters, newFilter.value]
    };
    if (newFilter.type === SEARCH_FILTER) {
      newFilters = {
        ...filters,
        [SEARCH_FILTER]: [newFilter.value]
      }
    }

    actions.updateFilters({ name, columnId: column.id, filters: newFilters });
    actions.fetchColumnData({ name, columnId: column.id, filters: newFilters, fetchData: service });
  };

  const handleRemoveFilter = (type, value) => () => {
    const { filters } = column;
    const newFilters = {
      ...filters,
      [type]: [...filters[type].filter(filterValue => filterValue !== value)]
    };
    if (type === SEARCH_FILTER) {
      setForm({ highlightedText: '', searchText: '' })
    }
    actions.updateFilters({ name, columnId: column.id, filters: newFilters });
    actions.fetchColumnData({ name, columnId: column.id, filters: newFilters, fetchData: service });
  };

  const handleRemoveColumn = () => {
    actions.removeColumn(name, column.id);
  };

  const handleUpdate = () => {
    actions.fetchColumnData({ name, columnId: column.id, filters: column.filters, fetchData: service });
  };

  const handleLoadMore = () => {
    actions.fetchColumnData({ name, columnId: column.id, filters: column.filters, page: column.nextPage, fetchData: service, });
  };

  const { data, isFetching, error, nextPage } = column;

  const openMenu = Boolean(menuAnchorEl);
  const menuId = openMenu ? 'simple-popover' : undefined;

  const filters = Object.entries(column.filters);

  return (
    <div className={classes.column}>
      <Paper className={classes.headerContainer} elevation={1} square={true}>
        <div className={classes.titleContainer}>
          <Typography variant="h5" color="textPrimary">
            {column.name}
          </Typography>
          <section>
            <RemoveColumnButton onClick={handleRemoveColumn} />
            <RefreshColumnButton onClick={handleUpdate} />
              <FilterColumnButton id={menuId} onClick={(e) => setMenuAnchorEl(e.currentTarget)} />
          </section>
          <FilterMenu
            id={menuId}
            anchorEl={menuAnchorEl}
            open={openMenu}
            onClose={() => setMenuAnchorEl(null)}
            onSelected={handleAddFilter}
            menuOptions={menuOptions}
          />
        </div>
        {filters.length ? (
          <div>
            {filters.map(([filterType, filterValues]) =>
              filterValues.map((value, i) => (
                <Chip
                  key={i}
                  className={classes.filterChip}
                  label={`${capitalize(filterType)}: ${capitalize(value)}`}
                  onDelete={handleRemoveFilter(filterType, value)}
                />
              ))
            )}
          </div>
        ) : null}
          <Form
            className={classes.searchBox}
            onSubmit={() => {
              emptySearchFilter();
              setForm({ ...form, highlightedText: form.searchText });
              handleAddFilter({ type: SEARCH_FILTER, value: form.searchText });
            }}
          >
            <TextField
              id="title"
              label="Search in title or body"
              type="text"
              margin="normal"
              fullWidth
              onChange={({ currentTarget: { value } }) => setForm({ ...form, searchText: value })}
              disabled={isFetching}
              required
              value={form.searchText}
            />
            <Button
              className={classes.applyBtn}
              color="primary"
              variant="contained"
              size="small"
              type="submit"
              disabled={isFetching}
            >
              Search
            </Button>
          </Form>
      </Paper>
      <section className={classes.bodyContainer}>
        <Body
          {...{
            error,
            isFetching,
            data,
            highlightedText: form.highlightedText,
            cardBuilder,
            nextPage,
            handleLoadMore,
          }}
        />
      </section>
    </div>
  );
}

export default Column;