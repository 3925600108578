import { fetchReports, clearReports } from 'actions/reports';
import { connect } from 'react-redux';
import { Reports } from 'views';

const mapStateToProps = state => ({
    reportsData: state.reports
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getReports: ( contentType, status, page, perPage) => {
      dispatch(fetchReports( contentType, status, page, perPage));
    },
    resetReports : ()=>{
      dispatch(clearReports());
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
