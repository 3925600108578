import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import TableChartIcon from '@material-ui/icons/TableChart';
import Button from 'components/commons/button';
import CustomSelect from 'components/commons/custom-select';
import Tooltip from '@material-ui/core/Tooltip';

import useStyles from './Reports.styles';


const guid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
const CustomToolbar = ({ actions, resetRows, reportsData, sendSelected }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState({
    Type: { Insight: { status: false }, Pledge: { status: false }, Survey: { status: false } },
    Status: {
      Live: { status: false },
      Draft: { status: false },
      Deactivated: { status: false },
      Review: { status: false },
      Prerelease: { status: false },
    },
  });
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    if (
      (selected.Type.Insight.status ||
        selected.Type.Pledge.status ||
        selected.Type.Survey.status) &&
      (selected.Status.Live.status ||
        selected.Status.Draft.status ||
        selected.Status.Deactivated.status ||
        selected.Status.Review.status ||
        selected.Status.Prerelease.status)
    ) {
      setEnable(true);
    } else {
      setEnable(false);
    }
    sendSelected(selected);
  }, [selected, sendSelected]);

  const handleClick = () => {
    let type;
    const typeArray = Object.keys(selected.Type);
    typeArray.forEach((typeString) => {
      if (selected.Type[typeString].status) {
        type = typeString.toLowerCase();
      }
    });
    let selectedStatusArray = [];
    const statusKeys = Object.keys(selected.Status);
    statusKeys.forEach((status) => {
      if (selected.Status[status].status) {
        selectedStatusArray.push(status);
      }
    });
    let status = selectedStatusArray.join('|').toLowerCase();
    if (status.includes('deactivated') && type !== 'pledge' && type !== 'insight') {
      status = status.replace('deactivated', 'deprecated');
    }

    actions.getReports(type, status, 1, 500);
  };

  const setSelection = (selection) => {
    const entryState =
      selection.type === 'Type'
        ? { Type: { ...selection.checkboxesState } }
        : { Status: { ...selection.checkboxesState } };
    if (selection.type === "Type") {
      resetRows();
      let selectedStatusReset = { ...selected };
      selectedStatusReset.Status.Live.status = false; selectedStatusReset.Status.Draft.status = false; selectedStatusReset.Status.Deactivated.status = false;
      selectedStatusReset.Status.Review.status = false; selectedStatusReset.Status.Prerelease.status = false;
      const selectedCopy = { ...selectedStatusReset, ...entryState };
      setSelected(selectedCopy);
    } else {
      const selectedCopy = { ...selected, ...entryState };
      setSelected(selectedCopy);
    }
  };
  return (
    <div className={classes.actionsContainer}>
      <div className={classes.itemsContainer}>
        <CustomSelect
          setSelection={setSelection}
          multiple={false}
          header="Type"
          options={selected.Type}
        />
      </div>
      <div className={classes.itemsContainer}>
        <CustomSelect
          setSelection={setSelection}
          multiple={true}
          header="Status"
          options={selected.Status}
          type={selected.Type}
        />
      </div>
      <div className={classes.toolbarContainer}>
        <div className={classes.itemsContainer}>
          <Button
            color="primary"
            disabled={!enable}
            onClick={handleClick}
            startIcon={<TableChartIcon className={classes.tableIcon} />}
          >
            Generate Report
          </Button>
        </div>
        <GridToolbar />
      </div>

    </div>
  );
};
const DataTable = ({ actions, reportsData }) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(50);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [firstRequest, setFirstRequest] = useState(true);
  const [selected, setSelected] = useState({});
  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
    {
      field: 'identifier',
      headerName: 'Id',
      type: 'number',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'string',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
    {
      field: 'priority',
      headerName: 'Priority',
      type: 'number',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
    {
      field: 'cooldown',
      headerName: 'Cooldown',
      type: 'number',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
    {
      field: 'contentType',
      headerName: 'Content Type',
      type: 'string',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
    {
      field: 'contentIdentifier',
      headerName: 'Identifier',
      type: 'string',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
    {
      field: 'introHeader',
      headerName: 'Header',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
    {
      field: 'introBody',
      headerName: 'Body',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.formattedValue}>
            <span className={classes.columTextWrapper}>
              {cellValues.formattedValue}
            </span>

          </Tooltip>
        );
      }
    },
  ];
  const resetRows = () => {
    setRows([]);
    setPage(0);
    setPageSize(50);
  };
  useEffect(() => {

    let rowsData = [];

    if (reportsData.reports.length >= 1 && firstRequest) {
      const statusKeys = Object.keys(selected.Status);
      const typeKeys = Object.keys(selected.Type);
      // eslint-disable-next-line
      const status = statusKeys.filter(status => {
        if (selected.Status[status].status) {
          return selected.Status[status].status;
        }
      });
      // eslint-disable-next-line
      const type = typeKeys.filter(type => {
        if (selected.Type[type].status) {
          return selected.Type[type].status;
        }
      });
      let statusFormated = status.join('|').toLowerCase();
      if (statusFormated.includes('deactivated') && type[0].toLowerCase() !== 'pledge' && type[0].toLowerCase() !== 'insight') {
        statusFormated = statusFormated.replace('deactivated', 'deprecated');
      }
      actions.getReports(type[0].toLowerCase(), statusFormated, 1, reportsData.meta.count);
      setFirstRequest(false);
    } else if (!firstRequest) {
      reportsData.reports.forEach((content, index) => {
        rowsData[index] =
        {
          status: content.status,
          id: guid(),
          identifier: content.id,
          type: content.type,
          priority: content.priority,
          cooldown: content.cooldown,
          contentType: content.content_type,
          contentIdentifier: content.content_identifier,
          name: content.name,
          introHeader: content.content_type === "insight" || content.content_type === "pledge" ? content.title : content.intro_header,
          introBody: content.content_type === "insight" || content.content_type === "pledge" ? content.body : content.intro_body
        }
      });
      setRows(rowsData); setPage(0);
      setFirstRequest(true);
    }






    // eslint-disable-next-line
  }, [reportsData.reports]);
  useEffect(() => {
    resetRows();
  }, []);


  const checkPagination = (page, pageSize) => {
    setPage(page)
  };
  const filter = (ev) => {
    const filterValue = ev.items.length >= 1 && ev.items[0].value !== undefined ? ev.items[0].value : null;
    const rowsData = [];
    if (filterValue === "") {
      reportsData.reports.forEach((content, index) => {
        rowsData[index] =
        {
          status: content.status,
          id: guid(),
          identifier: content.id,
          type: content.type,
          priority: content.priority,
          cooldown: content.cooldown,
          contentType: content.content_type,
          contentIdentifier: content.content_identifier,
          name: content.name,
          introHeader: content.content_type === "insight" || content.content_type === "pledge" ? content.title : content.intro_header,
          introBody: content.content_type === "insight" || content.content_type === "pledge" ? content.body : content.intro_body
        }
      });
      setRows(rowsData);
    }
    if (filterValue !== null && ev.items[0].columnField === "cooldown" && ev.items[0].operatorValue === "=" && filterValue === "0") {
      const filteredRows = rows.filter(row => row.cooldown === 0);
      setRows(filteredRows);
    }

  }
  const sendSelected = (selected) => {
    setSelected(selected);
  }
  return (
    <div>
      <div
        className={
          reportsData.isFetching ? classes.dataGridContainerHidden : classes.dataGridContainer
        }
        id="dataGridId"
      >
        <DataGrid
          onFilterModelChange={filter}
          pageSize={pageSize}
          rows={rows}
          columns={columns}
          autoHeight={true}
          checkboxSelection
          disableSelectionOnClick
          disableColumnMenu
          className={classes.dataGrid}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { actions, resetRows, reportsData, sendSelected } }}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            const currentItemsRange = page * pageSize;
            const division = currentItemsRange / newPageSize;
            if (newPageSize > pageSize) {
              if (currentItemsRange > newPageSize) {


                const pageNumber = 1 * Math.floor(division);
                setPage(pageNumber);
              } else if (currentItemsRange === newPageSize) {
                setPage(1);
              } else {
                setPage(0);
              }
            } else {
              setPage(division)
            }
          }
          }

          rowsPerPageOptions={[10, 50, 100]}
          onPageChange={(page) => { checkPagination(page, pageSize); }}
          page={page}
        />
      </div>
      {reportsData.isFetching ? <CircularProgress className={classes.circularProgress} /> : null}
    </div>
  );
};
const Reports = ({ actions, reportsData }) => {
  const classes = useStyles();
  useEffect(() => {
    if (reportsData.reports.length !== 0) {
      actions.resetReports();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5" gutterBottom>
        Reports
      </Typography>
      <DataTable actions={actions} reportsData={reportsData} />
    </div>
  );
};

export default Reports;

