import { authorizedAxios } from 'config/axios';

const FACTUARY_URL = '/factuary/api/v2';

export const fetchReports = ( contentType, status, page, perPage) => {
  return authorizedAxios.get(`${FACTUARY_URL}/content/filter/?content_type=${contentType}&status=${status}&page=${page}&per_page=${perPage}`).then((response) => {
    return {
        reports : response.data,
        meta : response.meta
    }
  });
};
