import {
  TYPE_LOAD_COLUMNS,
  TYPE_REQUEST_COLUMN_DATA,
  TYPE_RECEIVE_COLUMN_DATA,
  TYPE_RECEIVE_COLUMN_DATA_ERROR,
  TYPE_ADD_COLUMN,
  TYPE_UPDATE_COLUMN_FILTERS,
  TYPE_REMOVE_COLUMN,
  TYPE_PERSIST_COLUMNS
} from 'data/columns';
import { idGenerator } from 'utils/generator';

export const emptyColumn = {
  id: null,
  isFetching: false,
  data: [],
  name: 'Default column',
  filters: {}
};

export const initState = {
};

export default (state = initState, action) => {
  switch (action.type) {
    case TYPE_LOAD_COLUMNS: {
      const persistedColumns = JSON.parse(localStorage.getItem(action.name));
      const newColumns = persistedColumns?.length
        ? persistedColumns.map(column => ({
          ...emptyColumn,
          ...column,
          isFetching: true
        }))
        : [emptyColumn];
      return { ...state, [action.name]: newColumns };
    }

    case TYPE_PERSIST_COLUMNS:
      if (state[action.name]) {
        const persistedColumns = state[action.name]?.map(column => ({
          id: column.id,
          name: column.name,
          filters: column.filters
        }));
        localStorage.setItem(action.name, JSON.stringify(persistedColumns));
      }
      return state;

    case TYPE_REQUEST_COLUMN_DATA: {
      let newColum = state[action.name].map(column =>
        column.id === action.columnId
          ? {
            ...column,
            isFetching: action.isFetching
          }
          : column
      );
      return { ...state, [action.name]: newColum };
    }

    case TYPE_RECEIVE_COLUMN_DATA: {
      const newColumns = state[action.name].map(column => {
        if (column.id === action.columnId) {
          const newData = action.currentPage !== 1 ? [...column.data, ...action.data] : action.data;
          return {
            ...column,
            isFetching: action.isFetching,
            data: newData,
            nextPage: action.nextPage
          };
        } else {
          return column;
        }
      });
      return { ...state, [action.name]: newColumns };
    }

    case TYPE_RECEIVE_COLUMN_DATA_ERROR: {
      const newColumnsWithError = state[action.name].map(column => {
        if (column.id === action.columnId) {
          return {
            ...column,
            isFetching: action.isFetching,
            error: action.error ? action.error : state[action.name].error
          };
        } else {
          return column;
        }
      });
      return { ...state, [action.name]: newColumnsWithError };
    }

    case TYPE_ADD_COLUMN: {
      return {
        ...state,
        [action.name]: [
          ...state[action.name],
          { ...emptyColumn, name: action.columnName, id: idGenerator.next().value }
        ]
      }
    }

    case TYPE_REMOVE_COLUMN:
      return { ...state, [action.name]: state[action.name].filter(column => column.id !== action.columnId) };

    case TYPE_UPDATE_COLUMN_FILTERS: {
      const newColumns = state[action.name].map(column => {
        if (column.id === action.columnId) {
          return {
            ...column,
            filters: action.filters
          };
        } else {
          return column;
        }
      });

      return { ...state, [action.name]: newColumns }
    }

    default:
      return state;
  }
}