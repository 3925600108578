import * as Blockly from 'blockly/core';
import 'blockly/javascript';
import 'blockly/python';

import { getExpr } from '../utils';

// Python generator

Blockly.Python['dropdown_number_facts'] = function (block) {
  return [block.getField('fact').getText(), Blockly.Python.ORDER_NONE];
};

Blockly.Python['dropdown_text_facts'] = function (block) {
  return [block.getField('fact').getText(), Blockly.Python.ORDER_NONE];
};

Blockly.Python['logic_compare'] = function (block) {
  return `(test ${getExpr(block.getDescendants(true))})`;
};

Blockly.Python['logic_operation'] = function (block) {
  return `(test ${getExpr(block.getDescendants(true))})`;
};

Blockly.Python['logic_negate'] = function (block) {
  return `(test ${getExpr(block.getDescendants(true))})`;
};

Blockly.Python['math_arithmetic'] = function (block) {
  return `(test ${getExpr(block.getDescendants(true))})`;
};

Blockly.Python['user_cohort'] = function (block) {
  return [`${getExpr(block.getDescendants(true))}`, Blockly.Python.ORDER_EQUALITY];
};

// JavaScript generator

Blockly.JavaScript['dropdown_number_facts'] = function (block) {
  return [block.getField('fact').getText(), Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['dropdown_text_facts'] = function (block) {
  return [block.getField('fact').getText(), Blockly.JavaScript.ORDER_NONE];
};

// TODO: error with user_cohort inside another block
Blockly.JavaScript['user_cohort'] = function (block) {
  return [`(user_cohort.includes("${block.getDescendants(true)[2].getFieldValue('TEXT')}"))`, Blockly.JavaScript.ORDER_EQUALITY];
};