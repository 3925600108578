import * as service from 'services/reports';
import * as data from 'data/reports';

const requestReports= () => ({
  type: data.TYPE_REQUEST_REPORTS,
  isFetching: true,
});
const receiveReports= (json) => ({
    type: data.TYPE_RECEIVE_REPORTS,
    isFetching: false,
    reports : json.reports,
    meta : json.meta
  });
  const resetReports= () => ({
    type: data.TYPE_RESET_REPORTS,
  });
    
  const receiveReportsError= (error) => ({
    type: data.TYPE_RECEIVE_REPORTS_ERROR,
    isFetching: false,
    error : error.message ? error.message : error
  });
    

export const fetchReports =
  ( contentType, status, page, perPage) =>
  (dispatch) => {
    dispatch(requestReports());
    return service
      .fetchReports(contentType, status, page, perPage)
      .then((reports) =>  dispatch(receiveReports(reports)))
      .catch((error) => dispatch(receiveReportsError(error)));
  };

  export const clearReports = ()=>(dispatch)=>dispatch(resetReports());

